<template>
    <!-- 导航 -->
    <page-head title="最佳阵容"/>
    <content-bg>
        <template v-slot:content>
            <div class="main">
                <div class="formation_container">
                    <div
                        v-if="formationPosition && formationPosition.length"
                        v-for="(item, index) in formationPosition"
                        :key="index"
                        class="formation_card"
                        :style="{ 'left': item.left*100+'%', 'top': item.top*100+'%' }"
                    >
                        <p class="shirt">
                            <!-- <img class="shirt_icon" :src="index === 0 ? require('@i/team/green_shirt.svg') : require('@i/team/shirt.svg')" alt /> -->
                            <img
                                class="shirt_icon"
                                :src="require(`@i/team/camp_shirt_${item.position=='1'?'gk':'other'}${item.color ? item.color.value : '1'}.svg`)"
                                alt="">
                        </p>
                        <p class="corner"></p>
                        <div class="formation_player">
                            <div
                                v-if="item.playerList"
                                v-for="player in item.playerList.slice(0, 3)"
                                :key="player.id+item.position"
                                class="formation_item"
                            >
                                <p class="avatar_box">
                                    <img :src="$utils.defaultAvatar(player.avatar)"/>
                                </p>
                                <span class="number">{{ player.number }}</span>
                                <span class="name">{{ player.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </content-bg>
</template>

<script>
import {useRoute} from "vue-router";
import {getCurrentInstance, reactive, toRefs, onMounted} from "vue";
import {useStore} from "vuex";

export default {
    setup() {
        const store = useStore();
        const route = useRoute();
        const {proxy} = getCurrentInstance()
        const state = reactive({
            formationPosition: [],
        });

        const getData = async () => {
            const {id} = store.state.teamData;
            const {code, data} = await proxy.$server.getHonorList({type: 'lineup', teensCampId: id})
            if (code === 200 && data && data.length) {
                let formation = data[0].formation;

                console.log(formation)

                let formationObj = {};

                for (let f = 0; f < formation.positions.length; f++) {
                    formationObj[formation.positions[f].position] = formation.positions[f]
                }

                // console.log(formationObj)

                let formationData = data[0].formationData ? data[0].formationData : [];

                for (let i = 0; i < formationData.length; i++) {
                    for (let j = 0; j < formationData[i].positionNumber.length; j++) {

                        let position = formationData[i].positionNumber[j];

                        if (!formationObj[position[0]]['playerList']) {
                            formationObj[position[0]]['playerList'] = []
                        }

                        formationObj[position[0]]['playerList'].splice((position[1] - 1), 0 , formationData[i])

                    }
                }

                console.log(formationObj)

                state.formationPosition = [];
                for (const formationObjKey in formationObj) {
                    state.formationPosition.push(formationObj[formationObjKey])
                }

                console.log(state.formationPosition)

                // state.formationPosition = formation[Object.keys(formation)[0]];
            }
        };

        onMounted(() => {
            getData();
        });

        return {
            ...toRefs(state),
        }
    }
}
</script>

<style scoped lang="scss">
.main {
    position: relative;

    .formation_container {
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 823px;
        min-height: 736px;
        border-radius: 6px;
        background: url('~@i/team/team_formation_bg.svg') no-repeat;
        background-size: 100%;

        .formation_card {
            position: absolute;
            width: 151px;
            height: 140px;
            padding-top: 7px;
            border-radius: 4px;
            box-sizing: border-box;
            transform: translate(-50%, -50%);

            .shirt {
                width: 55px;
                height: 55px;
                position: absolute;
                top: -18px;
                right: 4px;
                z-index: 1;

                .shirt_icon {
                    width: 100%;
                    height: 100%;
                    color: #fff;
                }
            }

            .corner {
                width: 100%;
                height: 6px;
                border-radius: 4px 4px 0 0;
                background: rgba(8, 8, 8, 0.6);
                box-shadow: 0px 16.3938px 8.19689px rgba(0, 0, 0, 0.06);
            }

            .formation_player {
                height: 128px;
                box-sizing: border-box;
                border-radius: 0 0 4px 4px;
                padding: 12px 8px 8px;
                background: linear-gradient(180deg, rgba(8, 8, 8, 0.37) -8.7%, rgba(8, 8, 8, 0.22) 100%);
                box-shadow: 0px 16px 8px rgba(0, 0, 0, 0.06);
                backdrop-filter: blur(10px);
                overflow: hidden;

                .formation_item {
                    height: 35px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                    &:last-child {
                        border-bottom: 0;
                    }

                    .avatar_box {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;

                        img {
                            width: 100%;
                        }
                    }

                    span {
                        color: #fff;

                        &.number {
                            font-size: 17px;
                            margin-left: 7px;
                            margin-right: 5px;
                        }

                        &.name {
                            width: 50%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>